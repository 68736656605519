<script setup lang="ts">
// types
import type { FetchError } from 'ofetch'
import type { Student } from '@revolutionprep/types'

// composables
import {
  useCalendarStudentAvailability
} from '@/composables/calendarStudentAvailability'

/**
 * props
 * ==================================================================
 */
interface Props {
  student: Student
  show?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  show: false
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits([
  'interval-change',
  'toggle-dialog'
])

/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $toast } = useNuxtApp()

/**
 * state
 * ==================================================================
 */
const isLoading = ref(true)
const showCalendar = ref(false)
const showConfirmationDialog = ref(false)

/**
 * composables
 * ==================================================================
 */
const {
  calendarAvailabilitiesJson,
  defaultDuration,
  duration,
  isProcessing,
  requiredDuration,
  updateCalendarItems,
  updateStudentAvailabilities
} = useCalendarStudentAvailability()

const { doHandleError } = useErrorHandler()

/**
 * computed
 * ==================================================================
 */
const isCalendarDirty = computed(() => {
  if (
    calendarAvailabilitiesJson.value.length !==
    studentAvailabilitiesJson.value.length
  ) {
    return true
  }
  return Boolean(
    calendarAvailabilitiesJson.value.find((availability) => {
      return !studentAvailabilitiesJson.value.find((studentAvailability) => {
        return (
          JSON.stringify(studentAvailability) ===
          JSON.stringify(availability)
        )
      })
    })
  )
})

const studentAvailabilitiesJson = computed(() => {
  return props.student.availabilitiesJson
})

const toggleDialog = computed({
  get () {
    return props.show
  },
  set (newValue: boolean) {
    emit('toggle-dialog', newValue)
  }
})

/**
 * methods
 * ==================================================================
 */
async function submit () {
  try {
    isProcessing.value = true
    await updateStudentAvailabilities()
    await $actor.core.doRefetch()
    toggleDialog.value = false
    $toast.success('Your availability has been updated')
  } catch (errorResponse) {
    doHandleError(errorResponse as FetchError)
  } finally {
    isProcessing.value = false
  }
}

function closeDialog () {
  if (isCalendarDirty.value) {
    showConfirmationDialog.value = true
  } else {
    toggleDialog.value = false
  }
}

/**
 * watch
 * ==================================================================
 */
watch(() => props.show, (newValue: boolean) => {
  // 750ms padding for v-dialog transition before rendering calendar
  setTimeout(() => {
    showCalendar.value = newValue
  }, 750)
  setTimeout(() => {
    isLoading.value = !newValue
  }, 800)
})

/**
 * lifecycle hooks
 * ==================================================================
 */
onMounted(() => {
  duration.value = defaultDuration.value
})
</script>

<template>
  <v-dialog
    v-model="toggleDialog"
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title class="text-h6 d-flex pa-3">
        <div class="ms-2">
          Update Availability
        </div>
        <v-spacer />
        <v-icon
          data-test="btn-close-availability-dialog"
          @click="closeDialog"
        >
          mdi-window-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-if="isLoading"
          class="ma-2"
          type="image, image"
          width="100%"
          height="100%"
        />
      </div>
      <div style="overflow: auto;">
        <LazyCalendarStudentAvailability
          v-if="showCalendar"
          :required-duration="requiredDuration"
          :student-availabilities-json="studentAvailabilitiesJson"
          @update-calendar-items="updateCalendarItems"
        />
      </div>
      <v-divider />
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          color="secondary"
          :disabled="isLoading || isProcessing || !isCalendarDirty"
          variant="flat"
          @click="submit"
        >
          <lazy-r-spinner v-if="isProcessing" />
          <span v-if="isProcessing">
            Processing...
          </span>
          <span v-if="!isProcessing">
            Submit
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <lazy-r-dialog-confirmation
      :show="showConfirmationDialog"
      @confirm="toggleDialog = false; showConfirmationDialog = false;"
      @toggle-dialog="showConfirmationDialog = !showConfirmationDialog"
    >
      <template #dialog-title>
        Are you sure?
      </template>
      <template #dialog-text>
        Any availability you have modified, added or
        removed without clicking submit will be lost.
      </template>
    </lazy-r-dialog-confirmation>
  </v-dialog>
</template>
